import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './Gallery.module.css'

const Gallery = ({ pictures, showCategory }) => (
  <div className={styles.gallery}>
    {pictures.map((picture, index) => (
      <Link to={picture.slug} key={index} className={styles.gallery_image}>
        {showCategory && (
          <h5 className={styles.gallery_image_metadata}>{picture.type}</h5>
        )}
        <Img fluid={picture.image.childImageSharp.fluid} />
        <h2 className={styles.gallery_image_title}>{picture.title}</h2>
      </Link>
    ))}
  </div>
)

export default Gallery

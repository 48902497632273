import React from 'react'
import Layout from './Layout'
import Gallery from './Gallery'
import { pictures } from '../csvSelectors'

const GalleryPage = ({ data, currentPath, title }) => {
  const pics = pictures(data.allPicturesCsv.edges)
  return (
    <Layout currentPath={currentPath} title={title}>
      <h1>{title}</h1>
      <Gallery pictures={pics} />
    </Layout>
  )
}

export default GalleryPage
